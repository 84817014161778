import { getCookie } from '@/utils/cookie'
import * as Sentry from '@sentry/react'

type OptionalParams = {
  [key: string]: string | number | boolean;
};

type EventType = 'view' | 'tap';

type RecParams = OptionalParams & {
  elementid: string;
  eventtype: EventType;
};

class RprmUtils {
  /**
   * 初始化公共参数的方法，设置 userId
   */
  static initializePublicParams(): void {
    const userId = getCookie('userId')
    if (userId) {
      this.public({ wc_uid: userId })
    } else {
      console.warn('userId cookie is not found. Ignoring initializePublicParams call.')
    }
  }

  /**
   * 检查 rprm 是否已初始化
   */
  private static isRprmInitialized(): boolean {
    return typeof (window as any).rprm !== 'undefined' && (window as any).rprm !== null
  }

  /**
   * 手动打点方法，适用于异步打点场景。
   * @param elementid 元素ID
   * @param eventtype 事件类型（默认值为 'view'）
   * @param optionalParams 额外的可选参数
   * @param biUseFetch 是否强制使用 Fetch 进行打点（可选）
   */
  static rec(elementid: string, eventtype: EventType = 'view', optionalParams: OptionalParams = {}, biUseFetch?: boolean): void {
    const params: RecParams = { elementid, eventtype, ...optionalParams }
    if (this.isRprmInitialized()) {
      (window as any).rprm.rec(params, biUseFetch)
    } else {
      console.warn('rprm is not initialized. Ignoring rec call.')
    }
  }

  /**
   * 同步打点方法，适用于需要立即上报数据的场景。
   * @param elementid 元素ID
   * @param eventtype 事件类型（默认值为 'view'）
   * @param optionalParams 额外的可选参数
   * @param useSendBeacon 是否使用 navigator.sendBeacon 进行打点（在页面关闭时打点）
   * @param biUseFetch 是否强制使用 Fetch 进行打点（可选）
   */
  static dot(elementid: string, eventtype: EventType = 'view', optionalParams: OptionalParams = {}, useSendBeacon?: boolean, biUseFetch?: boolean): void {
    const params: RecParams = { elementid, eventtype, ...optionalParams }
    if (this.isRprmInitialized()) {
      (window as any).rprm.dot(params, useSendBeacon, biUseFetch)
    } else {
      console.warn('rprm is not initialized. Ignoring dot call.')
    }
  }

  /**
   * 设置或覆盖公共参数的方法。
   * @param params 公共参数
   * @param overwrite 是否覆盖现有参数
   */
  static public(params: OptionalParams, overwrite: boolean = false): void {
    if (this.isRprmInitialized()) {
      (window as any).rprm.public(params, overwrite ? undefined : 'new')
      Sentry.captureMessage(`userId dot public: ${JSON.stringify(params)}`)
    } else {
      Sentry.captureMessage(`userId dot public: rprm is not initialized`)
      console.warn('rprm is not initialized. Ignoring public call.')
    }
  }

  /**
   * pageShow
   */
  static pageShow(): void {
    if (this.isRprmInitialized()) {
      (window as any).rprm.pageShow()
    } else {
      console.warn('rprm is not initialized. Ignoring public call.')
    }
  }

  /**
   * 动态加载rprm.js脚本，并在加载后初始化。
   */
  static loadAndInitializeScript() {
    const isProduction = process.env.NODE_ENV === 'production'
    if (!isProduction) {
      console.warn('开发环境禁用打点')
      return
    }

    const userId = getCookie('userId')
    const publicParams = { 'wc_uid': userId, 'wc_path': location.pathname, 'wc_source': '', 'wc_scene_type': '' }

    if (location.search.indexOf('channelSource') !== -1) {
      try {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        const channelSource = params.get('channelSource') || ''
        publicParams['wc_source'] = channelSource.replace('ai1', '')
        console.log('初始化 设置wc_source  url,', channelSource)
      } catch (e) {
        console.error(e)
      }
    } else if (getCookie('bd_vid') || location.search.indexOf('bd_vid') !== -1) {
      console.log('初始化 设置wc_source 默认值', 'baidu')
      publicParams['wc_source'] = 'baidu'
    } else if (getCookie('channel_source')) {
      console.log('初始化 设置wc_source cookie', getCookie('channel_source'))
      publicParams['wc_source'] = getCookie('channel_source')
    }

    const script = document.createElement('script')
    script.src = 'https://cdn2.weimob.com/saas/@assets/rprm/1.5.40/hound.js'
    script.id = 'rprm_init'

    const { UMI_ENV } = process.env

    script.setAttribute('service', 'waic-pc')
    script.setAttribute('statType', '3957775490')
    script.setAttribute('ancAPI', '')
    script.setAttribute('trackAPI', '')
    script.setAttribute('env', (UMI_ENV === 'dev' || UMI_ENV === 'qa') ? 'qa' : 'live')
    script.setAttribute('anchor', 'true')
    script.setAttribute('public', JSON.stringify(publicParams))
    script.setAttribute('errcodes', '')
    script.setAttribute('whitelist', '')
    script.setAttribute('hm', 'true')
    script.setAttribute('apmRequestBody', 'true')
    script.setAttribute('biUseFetch', 'false')

    document.head.appendChild(script)
  }
}

// 动态加载并配置 rprm.js 脚本
RprmUtils.loadAndInitializeScript()

// 导出 RprmUtils 类作为默认导出
export default RprmUtils