async function getFileHex(file: File) {
  const buffer = await file.arrayBuffer()

  const uint8View = new Uint8Array(buffer)
  const hexCodes = []

  // 遍历每个字节并将其转换为十六进制
  for (let i = 0; i < uint8View.length; i++) {
    const hexCode = (uint8View[i] & 0xff).toString(16)
    hexCodes.push(hexCode.padStart(2, '0'))
  }

  return hexCodes
}

async function isValidJpgHex(file: File) {
  const hex = await getFileHex(file)
  const res = hex.slice(0, 2).join('').toUpperCase() === 'FFD8'
  return res
}

/**
 * 修正非法的 PNG 格式 MIME Type 为 JPEG
 */
async function transPng2Jpg(file: File) {
  const isPngType = file.type === 'image/png'

  if (isPngType) {
    const isJpgHex = await isValidJpgHex(file)

    if (isJpgHex) {
      console.log('>>>png2jpg start', file)
      const newFile = new File([file], file.name, { type: 'image/jpeg' })
      console.log('>>>png2jpg end', newFile)
      return newFile
    }
  }
  return file
}

export default transPng2Jpg
