import { Flex, Form, Modal, Space } from 'antd';
import Button from '@/components/Button';
import React, { useState } from 'react';
import styles from './index.less';

const Index = ({children, dismiss, cancelText = ' 取消 ', onCancel, okText=' 确定 ', onOk, ...otherProps}) => {

    const [modalConfig, setModalConfig] = useState(otherProps);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    const _cancelClick = async (e) => {
        const res = await onCancel?.(e);
        res !== false && dismiss();
    };

    const _okClick = async (e) => {
        setConfirmLoading(true);
        try {
            const values = await form.validateFields();
            const res = await onOk?.(e, values);
            res !== false && dismiss();
        } catch (e) {
            console.error('ModalWrapper _okClick error: ', e);
        }
        setConfirmLoading(false);
    };

    const changeModalConfig = (config) => {
        setModalConfig({
            ...modalConfig,
            ...config,
        });
    };

    return (
        <Modal
            centered
            open={true}
            destroyOnClose={true}
            className={styles.modalContainer}
            classNames={{
                footer: styles.footer
            }}
            cancelText={cancelText}
            okText={okText}
            {...modalConfig}
            onCancel={_cancelClick}
            onOk={_okClick}
            confirmLoading={confirmLoading}
        >
            {/*{children}*/}
            <Form form={form}>
                {React.cloneElement(children, { form, changeModalConfig })}
            </Form>
        </Modal>
    );
};

export default Index;