import React from 'react';
import ReactDOM, { createPortal, render } from 'react-dom';
import { createRoot } from 'react-dom/client';

interface PortalRenderProps {
  visible?: boolean;
  container?: Element;
  children?: React.ReactNode;
  // 唯一id
  name?: string;
}

// const PortalRender = (props: PortalRenderProps) => {
//   const { visible, container = document.body, name, children } = props;
//   const dom = name ? document.querySelector(`#${name}`) : null;
//   if (dom && visible) return null;

//   return visible ? createPortal(<div id={name || ''}>{children}</div>, container) : null;
// };

const PortalRender = {
  roots: {} as { [key: string]: any },
  create: (props: PortalRenderProps) => {
    const { name = '', children, container } = props;
    const dom = name ? document.querySelector(`#${name}`) : null;
    if (dom) return;
    const node = document.createElement('div');
    node.id = name;
    document.body.appendChild(node);
    const root = createRoot(node);
    root.render(createPortal(<>{children}</>, container || document.body));
    PortalRender.roots[name] = root;
  },

  unmount: (name: string) => {
    setTimeout(() => {
      const root = PortalRender.roots[name];
      if (root) {
        root.unmount();
        delete PortalRender.roots[name];

        const node = document.getElementById(name);
        if (node) {
          document.body.removeChild(node);
        }
      }
    });
  },
  unmountAll: () => {
    Object.keys(PortalRender.roots).forEach(key => {
      PortalRender.unmount(key);
    });
  },
};

export default PortalRender;
