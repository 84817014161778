export function addCookie(name: string, val: string, expireHours: number = 1, secure = false) {
  const date = new Date()
  const value = val

  // Set it expire in 7 days
  date.setTime(date.getTime() + expireHours * 60 * 60 * 1000)
  // console.log('addCookie cookie', name, val, expireHours, secure)
  document.cookie = name + '=' + value + '; expires=' + date.toUTCString()+'; path=/'

  // document.cookie =
  //   name +
  //   "=" +
  //   value +
  //   "; expires=" +
  //   date.toUTCString() +
  //   "; path=/; SameSite=None; Secure";

  // console.log('document.cookie', document.cookie)
}

export function addFixedTimeCookie(name: string, val: string,expireTime:any){
  console.log('addFixedTimeCookie',name,val,expireTime)
  document.cookie = name + '=' + val + '; expires=' + expireTime +'; path=/'
}

// 获取cookie
export function getCookie(name: string): string {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return ''
}

// 删除cookie
export function deleteCookie(name: string) {
  addCookie(name, '', -1)
}

export function clearAllCookie() {
  // console.log('clearAllCookie')
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (let i = keys.length; i--; ) deleteCookie(keys[i])
  }
}

export function clearAllCookieExceptRprm() {
  // console.log('clearAllCookieExceptRprm')
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (let i = keys.length; i--; ) {
      keys[i].indexOf('rprm_') !== 0 && deleteCookie(keys[i])
    }
  }
}

const cookie: any = {}
cookie.add = addCookie
cookie.get = getCookie
cookie.delete = deleteCookie
cookie.clear = clearAllCookie

export default cookie
