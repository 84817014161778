// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
// defineApp
export { defineApp } from './core/defineApp'
export type { RuntimeConfig } from './core/defineApp'
// plugins
export { proxy, useSnapshot, snapshot, subscribe, subscribeKey, proxyWithComputed, proxyWithHistory, proxyWithDevtools, proxyMap, proxySet, derive, underive, useProxy, ref, watch } from '/wm-pipeline/pipeline-source/src/.umi-production/plugin-valtio';
// plugins types.d.ts
// @umijs/renderer-*
export { createBrowserHistory, createHashHistory, createMemoryHistory, Helmet, HelmetProvider, createSearchParams, generatePath, matchPath, matchRoutes, Navigate, NavLink, Outlet, resolvePath, useLocation, useMatch, useNavigate, useOutlet, useOutletContext, useParams, useResolvedPath, useRoutes, useSearchParams, useAppData, useClientLoaderData, useRouteProps, useSelectedRoutes, useServerLoaderData, renderClient, __getRoot, Link, useRouteData, __useFetcher, withRouter } from '/wm-pipeline/pipeline-source/node_modules/@umijs/renderer-react';
export type { History } from '/wm-pipeline/pipeline-source/node_modules/@umijs/renderer-react'
// umi/client/client/plugin
export { ApplyPluginsType, PluginManager } from '/wm-pipeline/pipeline-source/node_modules/umi/client/client/plugin.js';
export { history, createHistory } from './core/history';
export { terminal } from './core/terminal';
// react ssr
export const useServerInsertedHTML: Function = () => {};
