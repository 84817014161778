import {getCookie} from "@/utils/cookie";
import { getQuery } from ".";
import { vistorQueryParams } from "@/constants";

const {UMI_ENV} = process.env;

export const desensitizationNumber = (str: string = '', isDesensitization: boolean = true) => {
    if (!str || !isDesensitization || str.length < 3) return '';
    const strs = str.replace(/\s+/g, '').split('');
    return strs
        .map((item, index) => {
            if (index > 2 && index < 7) return '*';
            return item;
        })
        .join('');
};

export const calDate = (targetDateString: string) => {
    if (!targetDateString) return 0;
    const targetDate = new Date(targetDateString);
    if (isNaN(targetDate.getTime())) return 0;  // Handle invalid date
    const currentDate = new Date();
    const differenceInMilliseconds = targetDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    return differenceInDays;
}

export const getRandomArr = (array: any[], count: number) => {
    if (!array || array.length < count) {
        return [];
    }

    const result = [];
    const taken = new Set();

    while (result.length < count) {
        const rand = Math.floor(Math.random() * array.length);
        if (!taken.has(rand)) {
            result.push(array[rand]);
            taken.add(rand);
        }
    }

    return result;
}

/**
 * @description 通过请求图片方式，进行数据上报
 * @param url 字符串
 * @return 最终字符串
 */
export const reportByImg = (url: string = ''): void => {
    if (url) {
        const img = new Image();
        img.src = url;
    } else {
        console.warn('URL 不能为空');
    }
};

/**
 * @description 通过请求图片方式，进行错误数据上报
 * @param options
 */
export const reportErrLogsByImg = (options = {}) => {
    const defaultOptions = {
        page: location.href,
        url: '',
        params: {},
        header: {},
        errorCode: '',
        errorMsg: '',
        pid: getCookie('userId'),
        userWid: getCookie('userId'),
        globalTicket: '',
        errSource: 'waic-pc',
    };

    // 合并默认选项和传入的选项
    const finalOptions = {...defaultOptions, ...options};

    // 序列化 params 和 header，并截取 params 长度
    finalOptions.params = JSON.stringify(finalOptions.params || '').substring(0, 200);
    finalOptions.header = JSON.stringify(finalOptions.header);

    // 生成查询字符串
    const queryString = Object.entries(finalOptions)
        .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
        .join('&');

    // 生成图片请求 URL
    const origin = (UMI_ENV === "dev" || UMI_ENV === "qa") ? "sales-qa.xiaoke.cn" : "www.xiaoke.cn";
    const imgUrl = `https://${origin}/api/zq/fe/node/errorlogs/add.gif?${queryString}`;

    // 发送图片请求
    reportByImg(imgUrl);
};

export const getImgWidthHeight = (url: string) => {

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = function () {
            resolve({
                width: img.width,
                height: img.height,
            });
        };
        img.onerror = function () {
            reject();
        };
    }) as Promise<{width: number,height: number}>;
}

export const numberToChinese=(numStr:any)=>{

    const str:any=String(numStr)||''
    const chineseNumbers = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    let result = '';
    for (let i = 0; i < str.length; i++) {
        result += chineseNumbers[str[i]];
    }
    return result;
}

export const getImageSize = (
	url: string,
): Promise<{
	width: number;
	height: number;
}> => {
	return new Promise((resolve) => {
		const img = new Image();
		img.src = url;
		img.onload = () => {
			resolve({
				width: img.width,
				height: img.height,
			});
		};
	});
};

/**
 * 将字符串转换为对象属性获取
 * @param str string
 * @param item object
 * @returns new object
 */
export const str2Obj = (str: string, item: Record<string, any>) => {
  const keys = str.split('.');
  const result = keys.reduce((acc, cur) => {
    return acc[cur];
  }, item);
  return result;
}


export const  isMac=()=>{
    console.log('navigator.platform',navigator.platform)
    return /Mac/.test(navigator.platform);
}

// 获取缩放倍数（1*系统缩放倍数*浏览器缩放倍数）
function getZoom() {
    let zoom = 1;
    const screen = window.screen,
      ua = navigator.userAgent.toLowerCase();
  
    if (window.devicePixelRatio !== undefined) {
      zoom = window.devicePixelRatio;
    } else if (~ua.indexOf('msie')) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
        zoom = screen.deviceXDPI / screen.logicalXDPI;
      }
    } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
      zoom = window.outerWidth / window.innerWidth;
    }
    return getDecimal(zoom);
  }
  
  const getDecimal = (num) => {
    return Math.round(num * 100) / 100;
  };
  
  export function getAllZoom() {
    // 总缩放倍数
    const zoom = getZoom();
    // 屏幕分辨率
    const screenResolution = window.screen.width;
    // 获取浏览器内部宽度
    const browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // 浏览器缩放倍数
    // 浏览器外部宽度不受浏览器缩放影响，浏览器内部宽度受影响,所以根据这个可以计算出浏览器缩放倍数（F12调试工具的占位会影响该值）
    const browserZoom = getDecimal(window.outerWidth / browserWidth);
    // 系统缩放倍数
    const systemZoom = getDecimal(zoom / browserZoom);
    // 系统分辨率
    const systemResolution = Math.round(screenResolution * systemZoom);
  
    console.log('系统分辨率', systemResolution, '屏幕分辨率', screenResolution, '浏览器外部宽度', window.outerWidth, '浏览器内部宽度', browserWidth, '总缩放倍数', zoom, '浏览器缩放倍数', browserZoom, '系统缩放倍数', systemZoom);
  
    return {
      zoom,
      browserZoom,
      systemZoom,
      systemResolution
    }
  }

  export  const getIsVistor = () => {
    const query = getQuery()
    let flag = false
    Object.keys(query).forEach(key => {
      if (vistorQueryParams.includes(key)) {
        flag = true
      }
    })
    return flag
  }