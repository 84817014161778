export const CLIPPATHPADDING = 32
export const IMGINITPOSITION = 100
export const AREA_WIDTH = 750
export const AREA_HEIGHT = 1000
export const AREA_HEIGHT_MIN = 750
export const AREA_HEIGHT_MAX = 2000
export const MAX_HISTORY_LENGTH = 10
export const IMAGE_SCALE = 0.6
export const DEFAULTZOOM = 0.3

export const TextBoxControlsVisibility = {
  mt: false, // middle top
  mb: false, // middle bottom
  ml: true, // middle left
  mr: true, // middle right
  bl: true, // bottom left
  br: true, // bottom right
  tl: true, // top left
  tr: true, // top right
  mtr: true,
}

export const ObjectControlsVisibility = {
  mt: false, // middle top
  mb: false, // middle bottom
  ml: false, // middle left
  mr: false, // middle right
  bl: true, // bottom left
  br: true, // bottom right
  tl: true, // top left
  tr: true, // top right
  mtr: true,
}

// python接收的类型 //python目前不需要这些类型，只需要1 image（商品图），5 融合图片，其他不需要
export enum ItemType {
  image = '1',
  logo = '2',
  title = '3',
  text = '4',
  hoverUnits = '5',
}

export const ControlsStyle = {
  borderColor: '#722AFF',
  borderScaleFactor: 2,
  cornerStrokeColor: '#fff',
  cornerColor: '#fff',
  cornerSize: 12,
  cornerStyle: 'circle',
  transparentCorners: false,
  padding: 0,
  centeredScaling: false,
  strokeUniform: true,
  paintFirst: 'stroke',
}

export const ActiveType = {
  activeSelection: 'activeSelection',
}

export enum LayerType {
  up = 'up',
  down = 'down',
  top = 'top',
  back = 'back',
}

export const UnitType: {
  group: unitType
  area: unitType
  text: unitType
  autoFontText: unitType
  widgetText: unitType
  widgetLogo: unitType
  image: unitType
  logo: unitType
  guideline: unitType
  workspace: unitType
  background: unitType
  rect: unitType
  path: unitType
  hoverUnits: unitType
  limitArea: unitType
  graphic: unitType
} = {
  // 区域类型，对应的画布是rect
  area: 'area',
  text: 'text',
  autoFontText: 'autoFontText',
  widgetText: 'widgetText',
  image: 'image',
  logo: 'logo',
  widgetLogo: 'widgetLogo',
  guideline: 'guideline',
    // 工作区，对应的画布是rect
  workspace: 'workspace',
    // 背景，对应的画布是rect 在workspace的上一层
  background: 'background',
  rect: 'rect',
  path: 'path',
  group: 'group',
  // 所有的非image元素
  hoverUnits: 'hoverUnits',
  limitArea: 'limitArea',
  graphic: 'graphic',
}

export const EasyAttrs = [
  'id',
  'imageUrl',
  'sourceImageUrl',
  'convertImageUrl',
  'cutoutedImageUrl',
  'userImage',
  'fontUrl',
  'anglePostion',
  'unitType',
  'dataId', // 画布外透传id
  'layerName',
  'labelName',
  'fixedWidth',
  'fixedHeight',
  'originalFontSize',
  'parentId',
  'selectable',
  'hasControls',
  'lockMovementX',
  'lockMovementY',
  'styles',
  'imageCaption',
  'splitByGrapheme',
  'masks',
  'maskUrl',
  'shouldBlend',
  'useCutOrSource'
]

export const TextUnits = [UnitType.text, UnitType.autoFontText, UnitType.widgetText]
export const ImageUnits = [UnitType.image, UnitType.logo, UnitType.widgetLogo]
export const ImageUnitsNoWidget = [UnitType.image, UnitType.logo]
export const SystemUnits = [
  UnitType.area,
  UnitType.guideline,
  UnitType.workspace,
  UnitType.background,
  UnitType.hoverUnits,
  UnitType.limitArea,
  UnitType.graphic,
]

// 不参与图层排序的元素
export const NotSortType = ['area', 'guideline', 'workspace']
// 一直置底的元素
export const alwaysToBottom = ['area', 'background', 'workspace']

export const NotSelectableType = ['area', 'guideline', 'workspace', 'background']

export const ImageSpecialAttrs = ['opacity', 'flipX', 'flipY', 'angle']

// 'resizing' ?
export const NeedUpdateAction = ['rotate', 'scale']

export const DefaultCanvasOptions = {
  selection: true,
  enableRetinaScaling: true,
  fireRightClick: true,
  controlsAboveOverlay: true, // 超出clipPath后仍然展示控制条
  imageSmoothingEnabled: true, // TODO 此处之前为false，确认是否某些场景适用 解决文字导出后不清晰问题
  preserveObjectStacking: true, // 当选择画布中的对象时，让对象不在顶层。
  stopContextMenu: true,
}

export const DefaultAreaOptions = {
  height: AREA_HEIGHT,
  selectable: false, // 设置为不可选中
  hasControls: false, // 禁用控制柄
  lockMovementX: true, // 锁定X轴移动
  lockMovementY: true, // 锁定Y轴移动
  hoverCursor: 'default',
  fill: '#E9E9F2', // 设置填充颜色为透明
  unitType: UnitType.area,
  stroke: 'transparent', // 设置边框颜色
  strokeWidth: 0, // 设置边框宽度为1px
  // stroke: 'black',
  // strokeWidth: 1,
  left: 0,
}

export const DefaultWorkSpaceOptions = {
  id: 'workspace',
  fill: '#E9E9F2',
  selectable: false,
  hasControls: false,
  strokeWidth: 0,
  hoverCursor: 'default',
  unitType: UnitType.workspace,
}

export const DefaultClipPathOptions = {
  id: 'workspace',
  selectable: false,
  hasControls: false,
  strokeWidth: 0,
  hoverCursor: 'default',
  unitType: UnitType.workspace,
}

export const DefaultUnitOptions = {
  textBox: {
    type: 'textbox',
    top: IMGINITPOSITION,
    fill: '#000000',
    fontWeight: 'normal',
    fontSize: 50,
    lineHeight: 1.3,
    textAlign: 'center',
    splitByGrapheme: true,
    fontFamily: '思源黑体',
    fontUrl: 'https://c.weimobwmc.com/ol-6LF7L/e337a6139ca94dc2b6ae9c2697b89f75.woff',
  },
}

export const ZoomLimit = {
  max: 4,
  min: 0.1,
}
export const ZOOM_STEP = 0.05

export const CanvasBackgroundImage =
  ' linear-gradient(45deg, #dedcdc 25%, transparent 0, transparent 75%, #dedcdc 0),linear-gradient(45deg, #dedcdc 25%, transparent 0, transparent 75%, #dedcdc 0)'
export const CanvasBackgroundPosition = '0px 0px, calc(16px + 0px) calc(16px + 0px)'
export const CanvasBackgroundSize = 'calc(16px * 2) calc(16px * 2)'
export const ArrowList = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']

// 小组件类型分配
export const getUnitType = (type: string) => {
  if (type === 'i-text') {
    return UnitType.widgetText
  }
  if (type === 'text' || type === 'textbox') {
    return UnitType.text
  }
  if (type === 'rect' || type === 'line' || type === 'path' || type === 'triangle' || type === 'circle' || type === 'polygon') {
    return type
  }
  return UnitType.widgetLogo
}

export const getDrawItemType = (type: unitType) => {
  if (type === UnitType.hoverUnits) {
    return ItemType.hoverUnits
  }
  if (TextUnits.includes(type as unitType)) {
    return ItemType.text
  }
  if (type === UnitType.image) {
    return ItemType.image
  }
  return ItemType.logo
}

export const validUrl = (str: string) => {
  const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
  return regex.test(str)
}
