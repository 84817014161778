import styles from './index.less';

const CustomerService = () => {
    return <div className={styles.customerServiceBox}>
        <div className={styles.leftPart}>
            <img className={styles.qrCode} src='https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/zhifuchenggongjiakefu-erweima@2x.png' />
            <div className={styles.desc}>如有疑问<br />请添加专属客服</div>
        </div>
        <img className={styles.bgImg} src='https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/zhifuchenggongjiakefu-zhuangshi.png' />
    </div>
};

export default CustomerService;