import classNames from 'classnames'
import styles from './index.less'
import { CSSProperties, HTMLAttributes } from 'react'
import { Loading3QuartersOutlined } from '@ant-design/icons'

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  disabled?: boolean
  children?: React.ReactNode
  type?: 'primary' | 'white' | 'transparent' | 'grey' | 'line' | 'light' | 'none' | 'line-white' | 'link' | 'transparent-white'
}

export default ({ children, type = 'primary', loading = false, style, ...arg }: ButtonProps) => {
  return (
    <button
      {...arg}
      style={{ ...style, pointerEvents: loading ? 'none' : 'unset' }}
      data-type={type}
      className={classNames(styles.btn, arg.className)}
    >
      {loading ? <Loading3QuartersOutlined style={{ fontSize: 16, marginRight: 9 }} spin /> : null}
      {children}
    </button>
  )
}
