import BindTel from './index'
import ReactDOM from 'react-dom/client'
import React from 'react'

export const openBindTel = (
  params: {
    onClose?: Function // 取消回调
    close?:boolean
    cb?:Function
  } ,
) => {
  const { onClose,close,cb, ...rest } = params
  const dom = document.getElementById('bindTelWrapper')
  dom && dom.parentElement && dom.parentElement.removeChild(dom)
  const container = document.createElement('div')
  // const id = new Date().getTime() + 'exportDialog';
  const id = 'bindTelWrapper'
  container.setAttribute('id', id)
  document.body.appendChild(container)
  const root = ReactDOM.createRoot(container)

  const cancel = () => {
    root.unmount()
    container && container.parentElement && container.parentElement.removeChild(container)
  }
  window.addEventListener('popstate', function (event) {
    cancel()
  })

  return root.render(
    <BindTel
       show={true}
       close={close}
       cb={cb}
       closeBindTel={e => {
        cancel()
        onClose && onClose(e)
      }}
      {...rest}
    />,
  )
}