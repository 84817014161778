import styles from './index.less';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '@/components/Button';
import { Dropdown, message } from 'antd';
import arrowDownFill from '@/assets/icon/arrow_down_fill.svg';
import * as API from './api';
import If from '@/components/If';
import { getUserInfo } from '@/utils/getUserInfo';

interface IProps {
    show?: Boolean,
    closeBindTel?: any,
    cb?: any,
    close?: boolean,
}

const bindTel = ({show, closeBindTel, close = true, cb} : IProps) => {
    const [regionCodeList, setRegionCodeList] = useState<IRegion[]>([])
    const [showBindTel, setShowBindTel] = useState(false);
    const [areaNo, setAreaNo] = useState('+86');
    const [phoneNum, setPhoneNum] = useState('');
    const [securityCode, setSecurityCode] = useState('');
    const [bind, setBind] = useState(false);
    const [countTime, setCountTime] = useState(60); // 倒计时显示
    const [countdown, setCountdown] = useState(false); // 开始倒计时
    const timer = useRef<any>(); // 倒计时定时器
    const count = useRef<any>(); // 倒计时计数器
    
    const inputTelChange = (e: any) => {
        const value = e.target.value;
        setPhoneNum(value);
        // 1.0.1 手动打点
        // if (!isDoted) {
        //   RprmUtils.rec(`newUser_tap_tel_login_input`, 'view', { en: 'tap', et: 'custom' })
        //   setIsDoted(true)
        // }
    };
    const inputCodeChange = (e: any) => {
        const value = e.target.value;
        setSecurityCode(value);
    };
    
    useMemo(() => {
        setBind(!!(phoneNum.trim() && securityCode.trim()));
    }, [phoneNum, securityCode]);
    
    const getCode = async () => {
        if (!phoneNum.trim()) return;
        if (areaNo == '+86' && phoneNum.trim().length != 11) {
            return message.error('请输入正确手机号');
        }
        try {
            await API.getCaptcha({
                mobileNo: phoneNum,
                zone: regionOpt.find((item: any) => item.key === areaNo)!.zone,
            });
            startCountDown();
        } catch(e : any) {
            if (e?.errcode === '45070002000003022') {
                const graRes = await API.graphicVerification();
                const { ticket, randstr } = graRes;
                await API.getCaptcha({
                    ticket,
                    randstr,
                    mobileNo: phoneNum,
                    zone: regionOpt.find((item: any) => item.key === areaNo)!.zone,
                });
                startCountDown();
            }
        }
    };
    
    const startCountDown = () => {
        clearInterval(timer.current);
        count.current = 60;
        setCountdown(true);
        timer.current = setInterval(() => {
            if (count.current === 0) {
                clearInterval(timer.current);
                count.current = 60;
                setCountdown(false);
            }
            --count.current;
            setCountTime(count.current);
        }, 1000);
    };
    
    const bindMobile = async () => {
        try {
            await API.bindMobile({
                mobileNo: phoneNum,
                code: securityCode,
                zone: regionOpt.find((item: any) => item.key === areaNo)!.zone,
            });
            await getUserInfo();
            cb && cb();
            if (location.href.indexOf('/personal-center') !== -1) {
                location.reload();
            }
            closeBindTel();
        } catch(e: any) {
            if (e?.errcode === '45070002000003003') {
                message.error(e.errmsg);
            }
        }
    };
    
    const regionOpt = useMemo(() => {
        return regionCodeList.map(item => ({
          ...item,
          label: (
            <span>
              {item.zoneName} <span className={styles.areaNum}>{item.displayName}</span>
            </span>
          ),
          key: item.displayName,
        }))
    }, [regionCodeList]);
      
    const showBindTelBox = () => {
        API.getZoneList().then((res: any) => {
            setRegionCodeList(res.data || []);
            setShowBindTel(true);
        }).catch();
    };
    
    useEffect(() => {
        if (show) {
            showBindTelBox();
        }
    }, [show]);
    
    return (
        showBindTel && <div className={`${styles.screenMask} ${showBindTel ? styles.openDialog : styles.closeDialog}`}>
            <div className={styles.container}>
                <div className={styles.telBox}>
                    {
                        close && <div className={styles.close} onClick={() => closeBindTel()} >
                            <div className="icon-Close"></div>
                        </div>
                    }
                    <div className={styles.contentBox}>
                        <div className={styles.title}>绑定手机号</div>
                        <div className={styles.hint}>为保障您的账户安全，请绑定手机号</div>
                        <div className={styles.inpItem}>
                            <Dropdown
                                getPopupContainer={tr => tr.parentElement?.parentElement?.parentElement || document.body}
                                menu={{ items: regionOpt, onClick: arg => setAreaNo(arg.key) }}
                                overlayClassName={styles.zoneDrop}
                            >
                                <div className={styles.areaNo}>{areaNo}<img src={arrowDownFill} /></div>
                            </Dropdown>
                            <input className={styles.inp} maxLength={areaNo === '+86' ? 11 : 20} placeholder="请输入手机号" onChange={inputTelChange} value={phoneNum} />
                        </div>
                        <div className={styles.inpItem}>
                            <input className={styles.inp} maxLength={4} placeholder="请输入验证码" onChange={inputCodeChange} value={securityCode} />
                            <If condition={countdown}
                                children={
                                    <div className={`${styles.code} ${styles.count}`}>{countTime}s</div>
                                }
                                else={
                                    <div className={styles.code} onClick={() => {getCode()}}>获取验证码</div>
                                }
                            />  
                        </div>
                        <Button onClick={() => {bindMobile()}} className={styles.bindBtn} disabled={!bind}>确认绑定</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default bindTel; 