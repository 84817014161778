import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react'
import styles from './index.less'
import classNames from 'classnames'
import { getActivityData, getActivityList, getCommonCheck } from '@/pages/home/api'
import openPayDialog from '@/components/PayDialog'

import { MemberType } from '@/constants'
import { useStore } from '@/states/store'
import { history } from 'umi'
import openIntercept from '../PayInterceptTourist'
import { openBindTel } from '../BindTel/openBindTel'

interface IProps {
  visible?: boolean
  onClose?: Function
  callback?: (isPop: boolean) => void
  type: 'create' | 'active'
  tabType?: number
  pathName?:string
  cb?: any // 首页的海报创作相似，需要判断是否登录、是否会员、是否9.9、是否拦截等等，判断条件结束后cb()
}
export default memo(({ visible, onClose, callback, type, cb,tabType,pathName}: IProps) => {
  const { userInfo } = useStore()

  const [showCoupon, setShowCoupon] = useState(false)

  const [couponData, setCouponData] = useState<Record<string, any>>()

  const [pageInfo, setPageInfo] = useState('')

  useEffect(() => {
    if (typeof visible === 'boolean' && couponData) {
      setShowCoupon(visible)
    }
  }, [visible, couponData])

  const initReq = async () => {
    if (!userInfo.userId) return
    if (userInfo.balance === void 0) return
    if (userInfo.memberStatus === MemberType.已开通) {
      if (type === 'create') {
        openPayDialog({
          pageInfo,
          type: tabType || 2,
        })
        setShowCoupon(false)
        onClose?.()
      }
      return
    }

    if (type === 'active') {
      const res = await getCommonCheck({ checkInType: 10000 })
      if (res?.data) return
      if (userInfo.balance > 200) return
    }
    if (type === 'create') {
      if (userInfo?.specialInfo?.isBuyGoods10) {
        openPayDialog({
          pageInfo,
          type: tabType || 1,
        })
        setShowCoupon(false)
        onClose?.()
        return
      }
    }

    return getActivityList().then(res => {
      // 默认拿第一个, 不考虑多个的情况
      const [activity] = res.data.list || []
      if (!activity) return
      return getActivityData(activity.activityId).then(res => {
        const [coupon] = res.data.giftList
        if (!coupon) return
        // 已使用 不再发放
        if (res.data.result === 3) {
          //  创作台弹出需要直接进入支付
          if (type === 'create') {
            openPayDialog({
              pageInfo,
              type: tabType || 1,
            })
            setShowCoupon(false)
            onClose?.()
          }
          return
        } else {
        }

        // 跳过9.9红包，直接到支付弹框
        if(type==='create'){
          handleUseNow()
        }else{
        setPageInfo(res.data.pageInfo)
        setShowCoupon(true)
        setCouponData(coupon)
        }

        return true
      })
    })
  }

  useEffect(() => {
    initReq().then(res => {
      callback?.(res)
      // callback?.(Boolean(res))
      if(!res&&userInfo.userId&&pathName){
        onClose?.()

      }
    })
  }, [userInfo.memberStatus, userInfo.balance, userInfo.specialInfo])

  const handleUseNow = () => {
    openPayDialog({
      pageInfo,
      needBindTel:false,
      onClose: (userInfo: any) => {
        if(pathName){
          if(!userInfo.isBindMobile&&userInfo?.memberStatus === MemberType.已开通){
            openBindTel({
              close:false,
              cb:()=>{
                  cb ? cb() : history.push({
                    pathname:pathName,
                  })
              }
            })
          }else if(userInfo?.memberStatus === MemberType.已开通){
            cb ? cb() : history.push({
              pathname:pathName,
            })
          }else{
            if(/create\/marketing-poster/.test(pathName)||/create\/product-details/.test(pathName)){
              openIntercept({pathName: pathName, cb, from: 'coupon'})
            }
          }
        }
      },

    })
    setShowCoupon(false)
    onClose?.()
  }

  return (
    showCoupon && (
      <div className={styles.couponMask}>
        <div className={styles.couponBox} onClick={handleUseNow}>
          <div
            className={classNames(styles.close, 'icon-Close')}
            onClick={(e) => {
                e.stopPropagation();
                setShowCoupon(false)
                onClose?.()
            }}
          />
          {/* <div>
            <p className={styles.price}>
              <span>¥</span>
              <span>{couponData?.coupon?.couponPrice}</span>
            </p>
            <p className={styles.text}>
              <span>{couponData?.coupon?.couponDescText}</span>
            </p>
            <button className={styles.btn} onClick={handleUseNow}>
              立即使用
            </button>
          </div> */}
        </div>
      </div>
    )
  )
})
