import request from "@/utils/request";


export const getUserInfo =  (data:any) => {
    return request({
        url: '/waic/account/user/info/get',
        data
      })
};

export const getGoodList =  (data:any) => {
  return request({
      url: '/waic/account/user/goods/getList',
      data
    })
};

export const getQrcode =  (data:any) => {
  return request({
      url: '/waic/account/user/pay/qrcode/get',
      data
    })
};
export const getStatus =  (data:any) => {
  return request({
      url: '/waic/account/user/pay/status/get',
      data
    })
};

