import { useEffect, useRef, useState } from 'react'
import { getQrcode, getStatus } from '../../api'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Popover, Statistic } from 'antd'
import styles from './index.less'
import { Spin } from 'antd'
import { addCookie, addFixedTimeCookie, deleteCookie, getCookie } from '@/utils/cookie'
import { useStore } from '@/states/store'
import CustomerService from '../CustomerService';

const { Countdown } = Statistic
type MyObjectWithIndexSignature = {
  [index: number]: string
}
const type: MyObjectWithIndexSignature = {
  1: 'member',
  2: 'bag',
}
export default ({
  currentTab = 1,
  goodsList = [],
  memberStatus = undefined,
  setTab = (tab: any) => {},
  updateUserInfo = (user: any) => {},
  payStatus = { member: 0, bag: 0 },
  setPayStatus = (value: any) => {},
  pageInfo = '',
}: any) => {
  const [currentId, setCurrentId] = useState(-1)
  const [payWay, setPayWay] = useState(1) //1微信 2支付宝
  const [qrCode, setQrCode] = useState('')
  const timer = useRef<any>()
  const [isCanPurchase, setIsCanPurchase] = useState(0) //是否能展示二维码 1否 0是
  const { userInfo } = useStore()
  const [cutdownShow, setCutdownShow] = useState(false)

  const deadLineTime = getCookie('activityDeadLineTime_' + userInfo.userId) || null
  // console.log('deadLineTimedeadLineTime',deadLineTime,getCookie('activityDeadLineTime_'+userInfo.userId))
  const [payResult, setPayResult] = useState<any>({
    member: {
      goodName: '',
    },
    bag: {
      goodName: '',
    },
  })
  const [memberExpireTime, setMemberExpireTime] = useState('')
  const [qrCodeLoading, setQrCodeLoading] = useState(false)

  const wximg = 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/icon_weixinzhifu.png'
  const zfbimg = 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/icon_zhifubaozhifu.png'
  const currentObj: any = goodsList.filter((i: any) => i.goodsId === currentId)?.[0] || {}
  const benefitType = userInfo?.benefitType; // 支付前存储 当前激活权益类型，判断是否是叠加体验卡

  useEffect(() => {
    if (userInfo.userId) {
      try {
        const cookieDeadLine = getCookie('activityDeadLineTime_' + userInfo.userId)
        let deadLineTime = new Date()
        deadLineTime.setHours(deadLineTime.getHours() + 1)
        if (!cookieDeadLine) {
          // deadLineTime.setMinutes(deadLineTime.getMinutes() + 1);

          // console.log('没有cookieDeadLine',deadLineTime.getTime(),deadLineTime.toString())
          addCookie('activityDeadLineTime_' + userInfo.userId, String(deadLineTime.getTime()), 25)
          setCutdownShow(true)
        } else {
          const storageTime = new Date(Number(cookieDeadLine))
          storageTime.setHours(storageTime.getHours() - 1)
          const startOfToday = new Date().setHours(0, 0, 0, 0)
          // console.log('有cookie',deadLineTime,new Date(startOfToday),storageTime,)
          if (startOfToday > storageTime.getTime()) {
            deleteCookie('activityDeadLineTime_' + userInfo.userId)
            addCookie('activityDeadLineTime_' + userInfo.userId, String(deadLineTime.getTime()), 25)
            setCutdownShow(true)
          } else {
            if (Number(cookieDeadLine) > new Date().getTime()) {
              setCutdownShow(true)
            }
          }
        }
      } catch (e) {}
    }
  }, [])

  useEffect(() => {
    try {
      const cookieDeadLine = getCookie('activityDeadLineTime_' + userInfo.userId)
      if (Number(cookieDeadLine) > new Date().getTime()) {
        setCutdownShow(true)
      } else {
        setCutdownShow(false)
      }
    } catch {}
  }, [currentTab])

  useEffect(() => {
    let hasDefault = -1

    if (goodsList?.length > 0) {
      goodsList.some((item: any) => {
        if (item.isDefault === 1) {
          hasDefault = item.goodsId
          return true
        }
      })
      if (pageInfo === 'banner' && currentTab === 1) {
        setCurrentId(3)
      } else if (hasDefault === -1) {
        setCurrentId(currentTab === 1 ? 2 : 5)
      } else {
        setCurrentId(hasDefault)
      }
    }

    return () => {
      clearInterval(timer.current)
    }
  }, [goodsList])

  useEffect(() => {
    if (currentId !== -1 && memberStatus !== undefined) {
      if (payStatus[type[currentTab]] === 1 || payStatus[type[currentTab]] === 2) {
        clearInterval(timer.current)
        return
      }
      if (currentTab === 2 && memberStatus != 1) {
        //清空计时器
        clearInterval(timer.current)
        return
      }

      getCode()
    }
  }, [currentId, payWay, memberStatus])
  const CreateTimer = (paySerial: any) => {
    timer.current = setInterval(() => continuousMonitoring(paySerial), 5000)
  }

  const continuousMonitoring = (paySerial: any) => {
    getStatus({ paySerial })
      .then((res: any) => {
        if (res.data) {
          const { payStatus: status, memberExpireTime: time } = res.data || {}

          if (currentTab === 1) {
            setPayStatus({ ...payStatus, member: status })
            if (status === 1) {
              setMemberExpireTime(moment(time).format('YYYY.MM.DD'))
            }
          } else {
            setPayStatus({ ...payStatus, bag: status })
          }
          if (status === 1) {
            updateUserInfo((user: any) => {
              // setUserInfo(user)
            })
          }
          if (status !== 0) {
            clearInterval(timer.current)
          }
        }
      })
      .catch((err: any) => {
        clearInterval(timer.current)
      })
  }
  const getCode = () => {
    //清空计时器
    // clearInterval(timer.current);
    if (currentTab === 1) {
      setPayStatus({ ...payStatus, member: 0 })
      setPayResult({
        ...payResult,
        member: {
          goodsId: currentId,
        },
      })
    } else {
      setPayStatus({ ...payStatus, bag: 0 })
      setPayResult({
        ...payResult,
        bag: {
          goodsId: currentId,
        },
      })
    }
    setQrCodeLoading(true)

    getQrcode({ goodsId: currentId, type: 1, useCoupon: currentObj?.useCoupon })
      .then((res: any) => {
        const { qrCodeUrl, paySerial, isCanPurchase = 0 } = res.data || {}
        //开始计时器
        clearInterval(timer.current)
        CreateTimer(paySerial)
        setQrCode(qrCodeUrl)
        setIsCanPurchase(isCanPurchase)
        setQrCodeLoading(false)
      })
      .catch((err: any) => {
        setQrCodeLoading(false)
      })
  }
  const itemClick = (id: any) => {
    setCurrentId(id)
  }
  const payWayClick = (way: any) => {
    setPayWay(way)
  }

  const renderPayCode = () => {
    if (currentTab === 2 && memberStatus !== 1) {
      return (
        <div className={styles.goToMember}>
          <div className={styles.tip}>
            加油包 <span>仅限会员购买</span>，非会员请先购买会员再购买加油包
          </div>
          <div
            className={styles.action}
            onClick={() => {
              setTab && setTab(1)
            }}
          >
            前往购买会员 {'>'}
          </div>
        </div>
      )
    } else if (isCanPurchase === 1) {
      return (
        <div className={styles.goToMember}>
          <div className={styles.tip}>
            你已开通 <span>连续订阅</span> 套餐，如果你想更换套餐，请先取消连续订阅。
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.paycontainer}>
          {/* <div className={styles.payment}>
        <div
          className={`${styles.payWay} ${payWay === 1 ? styles.activePay : ''}`}
          onClick={() => payWayClick(1)}
        >
          微信支付
        </div>
        <div
          className={`${styles.payWay} ${payWay === 2 ? styles.activePay : ''}`}
          onClick={() => payWayClick(2)}
          style={{ marginTop: 4 }}
        >
          支付宝支付
        </div>
      </div> */}
          <div className={styles.code}>
            <Spin
              className={styles.qrLoading}
              spinning={qrCodeLoading}
              indicator={<Loading3QuartersOutlined style={{ fontSize: 30 }} spin />}
            >
              <div className={styles.imgContainer}>
                {qrCode && <img src={qrCode} alt="" />}
                {payStatus[type[currentTab]] === 3 && (
                  <div
                    className={styles.codeMask}
                    onClick={() => {
                      getCode()
                    }}
                  >
                    <div className={`icon-rotate ${styles.update}`}></div>
                    刷新二维码
                  </div>
                )}
              </div>
            </Spin>
          </div>
          <div className={styles.payInfo}>
            <div className={styles.price} style={{ left: 0 }}>
              <div className={styles.priceSign}>￥</div>
              {currentObj?.sellAmount}
            </div>
            <div className={styles.paydes}>
              {(() => {
                if (currentObj.subscriptionType !== 0) {
                  return <img src={zfbimg} />
                } else {
                  return (
                    <>
                      <img src={zfbimg} alt="" />
                      <img src={wximg} alt="" />
                    </>
                  )
                }
              })()}
              请扫码完成支付
            </div>
            <div className={styles.xy}>
              开通服务即表示同意<span onClick={() => window.open(`/protocol?type=pay`)}>《WIME服务订购协议》</span>
            </div>
          </div>
        </div>
      )
    }
  }

  switch (payStatus[type[currentTab]]) {
    case 0:
    case 3:
      return (
        <div className={styles.content}>
          <div className={styles.goods}>
            {goodsList?.map((item: any) => {
              return (
                <div
                  key={'goods' + item.goodsId}
                  className={`${styles.item} ${item.goodsId === currentId ? styles.active : ''} ${
                    item.tagType === 2 ? styles.activityItem : ''
                  }`}
                  onClick={() => itemClick(item.goodsId)}
                >
                  {item.tagText && <div className={styles.newTip}>{item.tagText}</div>}
                  <div className={styles.goodName}>{item.goodsName}</div>
                  <div className={styles.price}>
                    <div className={styles.priceSign}>￥</div>
                    {item.showAmount}
                    {item.goodsId === 10&&<span className={styles.tiyan}>体验3天</span>}
                    {item.isFirstMonth && <div className={styles.firstMonth}>首月</div>}
                  </div>
                  {!(item.goodsId === 14||item.goodsId===10) && item.originalAmount && item.originalAmount !== item.showAmount && (
                    <div className={styles.originPrice}>原价￥{item.originalAmount}</div>
                  )}
                  {item.goodsId === 14 && (
                    <div className={styles.originPrice} style={{ textDecoration: 'none' }}>
                      约4800张图
                    </div>
                  )}
                  {item.goodsId === 10 && (
                    <div className={styles.originPrice} style={{ textDecoration: 'none' }}>
                      到期自动续38元/月
                    </div>
                  )}
                  {!(item.goodsId === 14) && (
                    <div className={styles.bottom}>
                      <span className={styles.bottomText}>{item.amountContent}</span>
                    </div>
                  )}
                  {item.goodsId === 14 && (
                    <div className={styles.bottom}>
                      {cutdownShow ? (
                        <div className={styles.cutDown}>
                          <Countdown
                            title={null}
                            value={Number(deadLineTime)}
                            format="mm:ss:SSS"
                            onFinish={() => {
                              console.log('onFinishonFinishonFinish', new Date().getTime(), deadLineTime)
                              setCutdownShow(false)
                            }}
                          />
                          <span className={styles.bottomText}> 后结束</span>
                        </div>
                      ) : (
                        <span className={styles.bottomText}>{item.amountContent}</span>
                      )}
                    </div>
                  )}
                </div>
              )
            })}
          </div>

<div className={styles.des}>
<span dangerouslySetInnerHTML={{ __html: currentObj?.goodsDesc }} ></span>
{currentObj?.subscriptionType !== 0&&<Popover 
placement='bottomRight'
overlayClassName={styles.tipPopContainer} content={
  <div className={styles.popTip}>
自动续费服务声明<br/>
1.到期当天为您自动续费<br/>
2.可随时取消自动续费服务，取消后不再自动续费<br/>
3.可在【个人中心-订单记录-取消自动续费】中查看取消自动续费方法
  </div>
}>
<span className={`${styles.tipIcon} icon-SignQuestionFill`}></span>
</Popover>
}
</div>
          
          <div className={styles.payCode}>{renderPayCode()}</div>
        </div>
      )
      break
    case 1:
      let curResultObj = {}
      if (currentTab === 1) {
        curResultObj = goodsList.filter((i: any) => i.goodsId === payResult['member'].goodsId)?.[0] || {}
      } else {
        curResultObj = goodsList.filter((i: any) => i.goodsId === payResult['bag'].goodsId)?.[0] || {}
      }
      return (
        <div className={styles.content}>
          <div className={`${styles.payResult} ${styles.successPart}`}>
            <div className={styles.ac}>
                <div className={`icon-StatusSuccess ${styles.icon}`} />
                <div className={styles.result}>支付成功</div>
            </div>
            {currentTab === 1 && (
              <div className={styles.des1}>
                恭喜{ (benefitType === 7 || benefitType === 99) ? '您已升级' : '你已成' }为 <span>{curResultObj?.goodsName || ''}</span>{' '}
                {(curResultObj?.subscriptionType !== 0 || curResultObj?.goodsId === 14) && '会员'}
                {(benefitType === 7 || benefitType === 99) && '，体验卡中剩余微力已累加到新会员权益中。'}
              </div>
            )}
            {currentTab === 1 && (
              <div className={styles.des2}>
                会员有效期：{memberExpireTime}
              </div>
            )}
            {currentTab === 2 && (
              <div className={styles.des}>
                恭喜你已成功购买 <span>{curResultObj?.goodsName || ''}</span> 加油包
              </div>
            )}
          </div>
          <CustomerService />
        </div>
      )
    case 2:
      return (
        <div className={styles.content}>
          <div className={`${styles.payResult} ${styles.failPart}`}>
            <div className={styles.ac}>
                <div className={`icon-StatusFailed ${styles.icon}`} />
                <div className={styles.result}>支付失败</div>
            </div>
            <div className={styles.desc}>请尝试重新进行支付</div>
          </div>
          <CustomerService />
        </div>
      )
    default:
      return <div></div>
  }
}
