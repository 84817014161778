import { defineApp } from 'umi'
import RprmUtils from '@/utils/dot'
import { setHeaders } from './utils/request'
// import initPlatform from './utils/platform'

// initPlatform()

export default defineApp({
  onRouteChange: ({ location, isFirst }) => {
    if (!isFirst) {
      RprmUtils.public({
        'wc_path': location.pathname,
      })
      RprmUtils.pageShow()
    } else {
      // document.title='WIME'
      setHeaders()
    }
  },
})
