import { Flex, Modal, Space } from 'antd'
import styles from './index.less'
import openPayDialog from '@/components/PayDialog'
import PortalRender from '../PortalRender'
import MaskCoupon from '../MaskCoupon/MaskCoupon'
import { ModalFuncProps } from 'antd/es/modal/interface'
import ReactDOM from 'react-dom/client'
import Button from '@/components/Button'
import React from 'react'
import ModalWrapper from '@/components/ModalDialog/ModalWrapper'
import classNames from 'classnames'
import { getUserInfo } from '@/states/store'

export const OpenModalDialog = (type: number) => {
  //0微力不足 1会员过期
  // const divDom=document.getElementsByClassName('MidPayDialogWrapper')
  // if(divDom&&divDom.length>0){
  //   return
  // }
  const userInfo = getUserInfo()

  Modal.confirm({
    title: (
      <div className="modalTitle">
        {' '}
        <img
          src={`${
            type === 0
              ? 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/anniu_dou.png'
              : 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/anniu_huiyuan.png'
          }`}
        />{' '}
        {type === 0 ? '微力额度不足' : 'WIME会员到期'}
      </div>
    ),
    content: `${type === 0 ? '很遗憾，您的微力额度不足，无法完成本次创作' : '很遗憾，您的会员已到期，无法完成本次创作'}`,
    centered: true,
    autoFocusButton: null,
    icon: null,
    cancelText: ' 取消 ',
    okText:
      type === 0 ? (
        <div className={styles.tipWrap}>
          购买微力{userInfo?.memberStatus === 1 || userInfo?.specialInfo?.isBuyGoods10 ? null : <span className={styles.tip}>限时9.9元体验卡</span>}
        </div>
      ) : (
        '购买会员'
      ),
    wrapClassName: `${styles.wrapper}`,
    className: 'MidPayDialogWrapper',
    onOk() {
      PortalRender.create({
        name: 'maskCoupon',
        children: (
          <MaskCoupon
            type="create"
            tabType={type === 0 ? 2 : 1}
            onClose={() => {
              PortalRender.unmount('maskCoupon')
            }}
          />
        ),
      })
    },
    onCancel() {
      console.log('触发cancel')
      PortalRender.unmount('maskCoupon')
    },
  })
}

interface IDialogProps {
  content: React.ReactNode
  dialogName?: string
  modalConfig?: any
}

const confirm = (modalProps: ModalFuncProps) => {
  const { cancelText = ' 取消 ', okText = ' 确定 ', wrapClassName } = modalProps
  Modal.confirm({
    centered: true,
    autoFocusButton: null,
    icon: null,
    cancelText,
    okText,
    ...modalProps,
    wrapClassName: classNames(styles.wrapper, wrapClassName),
    title: <div className="modalTitle">{modalProps.title}</div>,
  })
}

let index = 0
const dialogCache = new WeakMap()

const _dismiss = (id: string) => {
  const dom = document.getElementById(id)
  if (!dom) {
    return
  }
  const root = dialogCache.get(dom)
  root?.unmount()
  dom && dom.parentElement && dom.parentElement.removeChild(dom)
  dialogCache.delete(dom)
}

const show = ({ content, dialogName, modalConfig = {} }: IDialogProps) => {
  const id = `dialog-${dialogName ? dialogName : index++}`
  const dialogInfo = dialogCache[id]
  if (dialogInfo) {
    _dismiss(id)
  }
  const container = document.createElement('div')
  container.setAttribute('id', id)
  document.body.appendChild(container)
  const root = ReactDOM.createRoot(container)

  //
  dialogCache.set(container, root)

  const dismiss = () => {
    _dismiss(id)
  }
  window.addEventListener('popstate', function (event) {
    dismiss()
  })
  const dialog = (
    <ModalWrapper {...modalConfig} dismiss={dismiss}>
      {content}
    </ModalWrapper>
  )
  root.render(dialog)
  return {
    dismiss,
  }
}

export default {
  confirm,
  show,
}
