import { setUserInfo } from '@/states/store'
import request from '@/utils/request'
import { getCookie } from '@/utils/cookie'
import * as Sentry from '@sentry/react'

export const getUserInfo = async () => {
  if (getCookie('token')) {
    const res = await getUserApi()
    const { data } = res || {}
    // setUserInfo({ ...data });
    setUserInfo({ ...data })
    Sentry.setUser({
      id: data.userId,
      username: data.nickname,
      memberStatus: data.memberStatus,
      mobile: data.mobileNo,
    })
    return data;
  }
}

const getUserApi = () => {
  return request({
    url: '/waic/account/user/info/get',
  })
}
