import React, { ReactNode, useEffect, useMemo, useState, useRef } from 'react'
import styles from './index.less'
import ReactDOM from 'react-dom/client'
import { history } from 'umi'
import RprmUtils from '@/utils/dot'
import { getGoodList, getQrcode, getStatus } from './api'
import { Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { getUserInfo } from '@/utils/getUserInfo';
import { MemberType } from '@/constants';
import { openBindTel } from '@/components/BindTel/openBindTel';

const getCurrentType: (pathName: string) => string = (pathName: string) => {
    if (/create\/marketing-poster/.test(pathName)) {
        return 'marketingPoster'
    }
    if (/create\/product-details/.test(pathName)) {
        return 'productDetails'
    }
    return 'productMainImage';
};

// 首页的海报创作相似，需要判断是否登录、是否会员、是否9.9、是否拦截等等，判断条件结束后cb()
const Intercept = ({ pathName, onCancel, cb, close = true, from = '' }: any) => {
    const [goodsItem, setGoodsItem] = useState<any>({});
    const [qrCodeLoading, setQrCodeLoading] = useState(false)
    const [qrCode, setQrCode] = useState('')
    const [payStatus, setPayStatus] = useState(0);

    let timer = useRef<any>();

    const followList = ['route', 'coupon']; // 路由文件、9.9券

    const getGoodsList = () => {
        getGoodList({ goodsType: 1 })
            .then((res: any) => {
                const { data } = res || {}
                setGoodsItem(data.find((_: any) => _.subscriptionType !== 0));
            })
            .catch(err => { })
    };

    const getCode = () => {
        if (!goodsItem?.goodsId) return;
        setQrCodeLoading(true);
        getQrcode({ goodsId: goodsItem.goodsId, type: 1, useCoupon: goodsItem?.useCoupon, payLocation: from === 'vistorProductDetail' ? 'vistorProductDetail' : undefined })
            .then((res: any) => {
                const { qrCodeUrl, paySerial, isCanPurchase = 0 } = res.data || {}
                //轮询支付状态
                createTimer(paySerial)
                setQrCode(qrCodeUrl)
                setQrCodeLoading(false)
            })
            .catch((err: any) => {
                setQrCodeLoading(false)
            });
    };

    const createTimer = (paySerial: any) => {
        clearInterval(timer.current);
        timer.current = setInterval(() => {
            getStatus({ paySerial }).then((res: any) => {
                if (res.data) {
                    const { payStatus } = res?.data;
                    setPayStatus(payStatus);
                    if (payStatus !== 0) {
                        clearInterval(timer.current);
                    }
                    if (payStatus === 1 && cb) {
                        cb(res.data);
                        onCancel && onCancel()
                    }
                    if (payStatus === 1 && followList.includes(from)) {
                        follow();
                        onCancel && onCancel();
                    }
                }
            }).catch();
        }, 5000);
    };

    const follow = async () => {
        const userInfo: any = await getUserInfo();
        if (userInfo?.memberStatus === MemberType.已开通) {
            if (userInfo.isBindMobile) {
                return cb ? cb() : history.push({
                    pathname: pathName,
                });
            }
            openBindTel({
                close: false,
                cb: () => {
                    cb ? cb() : history.push({
                        pathname: pathName,
                    });
                }
            });
        }
    };

    useEffect(() => {
        getGoodsList();
        if (from === 'vistorProductDetail') {
            RprmUtils.rec(`vistor_shangxiang_showPayIntercept`, 'view', { en: 'tap', et: 'custom' })
        }
    }, []);

    useEffect(() => {
        getCode();
    }, [goodsItem]);

    return (
        <div className={`${styles.containerWrapper} ${getCurrentType(pathName) === 'marketingPoster' && styles.marketingPoster} ${from === 'vistorProductDetail' && styles.vistorProductDetail}`}>
            <div className={styles.container}>
                {
                    close && <div className={`${styles.closeBtn} payInterceptClose`} onClick={() => {
                        onCancel();
                        if (from === 'vistorProductDetail') {
                            window.location.href = '/';
                        }
                    }}>
                        <span className="icon-Close payInterceptClose"></span>
                    </div>
                }
                <div className={styles.rightPayBox}>
                    <div className={styles.priceBox}>
                        <div className={styles.unit}>￥</div>
                        <div className={styles.price}>{goodsItem.showAmount}</div>
                        {
                            goodsItem.goodsId === 10 && <div className={styles.topTip}>3天体验</div>
                        }
                    </div>
                    {
                        goodsItem.goodsId === 10 && <div className={styles.tipHint}>到期自动连续包月</div>
                    }
                    {
                        goodsItem.goodsId === 8 && <div className={styles.tipBox}>连续包月</div>
                    }
                    <div className={styles.qrCodeBox}>
                        <Spin
                            className={styles.qrLoading}
                            spinning={qrCodeLoading}
                            indicator={<Loading3QuartersOutlined style={{ fontSize: 30 }} spin />}
                        >
                            {qrCode && <img src={qrCode} className={styles.qrCode} />}
                            {payStatus === 3 && (
                                <div
                                    className={styles.codeMask}
                                    onClick={() => getCode()}
                                >
                                    <div className={`icon-rotate ${styles.update}`} />
                                    刷新二维码
                                </div>
                            )}
                        </Spin>
                    </div>
                    <div className={styles.hintBox}>
                        <img className={styles.payIcon} src="https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/icon_zhifubaozhifu.png" />
                        <div>请扫码完成支付</div>
                    </div>
                    {
                        goodsItem.goodsId === 10 && <div className={styles.autoHint}>到期自动续38元/月</div>
                    }
                    <div className={styles.clauseBox}>
                        <div>开通服务即表示同意</div>
                        <div className={styles.clause} onClick={() => window.open(`/protocol?type=pay`)}>《WIME服务订购协议》</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const imgMap: any = {
    productDetails: ['https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/tuiguang-shangxiang-ercilanjieyouhua@3x.png'],
    marketingPoster: ['https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/haibao-ercilanjieyouhua@3x.png'],
    vistorProductDetail: ['https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/tuiguang-shangxiang-ercilanjieyouhua@3x.png', 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/tuiguang-shangpinxiangqingbg@1x.jpg'],
};

const imgLoadFn = (url: string) => new Promise((resolve) => {
    const Img = new Image();
    Img.onload = () => {
        resolve(true);
    };
    Img.src = url;
});

const imgsPreLoad = async ({ from, pathName }: any) => {
    const imgList = from === 'vistorProductDetail' ? imgMap.vistorProductDetail : imgMap[getCurrentType(pathName)];
    const imgPromiseList = imgList.map(async (img: string) => {
        const imgPromise = await imgLoadFn(img);
        return imgPromise;
    });
    await Promise.all(imgPromiseList);
};

const openIntercept = async ({ pathName, cb, close, from }: any) => {
    console.log(pathName, from, '=================')
    await imgsPreLoad({ from, pathName });

    const dom = document.getElementById('payInterceptDialog')
    dom && dom.parentElement && dom.parentElement.removeChild(dom)
    const container = document.createElement('div')
    // const id = new Date().getTime() + 'exportDialog';
    const id = 'payInterceptDialog'
    container.setAttribute('id', id)
    container.classList.add(styles.payWrapper)
    document.body.appendChild(container)

    const root = ReactDOM.createRoot(container)
    const cancel = () => {
        root.unmount()
        container && container.parentElement && container.parentElement.removeChild(container)
    }
    window.addEventListener('popstate', function (event) {
        cancel()
    })

    return root.render(
        <Intercept
            getContainer={() => container} // 注意这里我们改为返回container的函数，因为getContainer通常是一个函数
            pathName={pathName}
            cb={cb}
            close={close}
            from={from}
            onCancel={() => {
                cancel()
                //   onClose && onClose(userInfo)
            }}
        />,
    )
}

export default openIntercept;
