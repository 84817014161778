import { message } from 'antd'
import imageCompression, { Options } from 'browser-image-compression'
import transPng2Jpg from './transPng2Jpg'

const MAX_SIZE = 20
const MAX_EDGE = 2048

type ImageWH = {
  width: number
  height: number
}

const getImageWH = async (file: File): Promise<ImageWH> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (e) {
      const img = new Image()

      img.onload = function () {
        const width = img.width
        const height = img.height
        resolve({ width, height })
      }

      img.onerror = function () {
        reject({
          message: '图片加载错误',
          file,
        })
      }

      img.src = e.target?.result as string
    }

    reader.onerror = () => {
      reject({
        message: '图片读取错误',
        file,
      })
    }

    reader.readAsDataURL(file)
  })
}

type CompressImageOptions = Options & {
  useSize?: boolean
  useWH?: boolean
}
const compressImg = async (file: File, options?: CompressImageOptions) => {
  try {
    const opt = {
      useWebWorker: true,
      ...options,
    }

    if (options?.useSize) {
      opt.maxSizeMB = options?.maxSizeMB ?? MAX_SIZE
    }

    if (options?.useWH) {
      opt.maxWidthOrHeight = options?.maxWidthOrHeight ?? MAX_EDGE
    }

    console.log('>>>compress start', file, opt)
    const compressedFile = await imageCompression(file, opt)
    console.log('>>>compress end', compressedFile)

    return compressedFile
  } catch (err) {
    console.error(err)
    return file
  }
}

const compressImage = async (files: File[], options?: Options) => {
  let tipOnce = false

  const p0 = files.map(async file => {
    const wh = await getImageWH(file)
    console.log('>>>wh', wh)

    const maxSize = options?.maxSizeMB ?? MAX_SIZE
    const maxEdge = options?.maxWidthOrHeight ?? MAX_EDGE

    const useSize = file.size >= maxSize * 1024 * 1024
    const useWH = wh.width >= maxEdge || wh.height >= maxEdge

    if (!tipOnce) {
      if (useSize || useWH) {
        tipOnce = true
        message.info('您上传的图片过大，正在为您压缩处理中，请耐心等待')
      }
    }

    const data = {
      file,
      options,
      useSize,
      useWH,
    }

    return data
  })

  // 先出提示
  const p1 = await Promise.all(p0)

  const p = p1.map(async res => {
    const { file, options, useSize, useWH } = res

    if (useSize || useWH) {
      console.log('>>>useCompress', file)
      const newFile = await transPng2Jpg(file)

      return compressImg(newFile, {
        ...options,
        useSize,
        useWH,
      })
    }

    console.log('>>>useOrigin', file)
    return file
  })

  return Promise.all(p)
}

export default compressImage
