import type { ReactNode } from "react";

interface IProps {
  condition?: any;
  children?: ReactNode;
  else?: ReactNode;
}

export default ({ children, condition, else: elseRender }: IProps) => {
  return <>
    {Boolean(condition) ? children : elseRender}
  </>
}