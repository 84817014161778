import request from "@/utils/request";

interface ITencentCaptcha {
    ret: number; //验证结果，0：验证成功。2：用户主动关闭验证码。
    ticket: string; //String	验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
    appid: string; //场景 ID。
    bizState: any; //自定义透传参数。
    randstr: string; //本次验证的随机串，请求后台接口时需带上
}

/**
 * 获取手机区号
 */
export const getZoneList = () => {
    return request({
      url: '/waic/account/console/zone/getList',
    });
};

/**
 * 获取验证码
 */
export const getCaptcha = (data: AnyObject) => {
    return request({
      url: '/waic/account/console/captcha/get',
      data,
    })
}

// 滑块
export const graphicVerification = (): Promise<ITencentCaptcha> => {
    return new Promise((resolve, reject) => {
      const TencentCaptcha = (window as any).TencentCaptcha
      var captcha = new TencentCaptcha('196101942', function(res: any) {
        if (res.ret === 0) {
          resolve(res)
        } else {
          reject({})
        }
      })
      captcha.show() // 显示验证码
    })
}

/**
 * 绑定手机号
 */
export const bindMobile = (data: AnyObject) => {
    return request({
      url: '/waic/account/user/mobile/bind',
      data,
    })
}