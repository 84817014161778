import request from '@/utils/request';

export const getTagList = (data: any) => {
  return request({
    url: '/waic/core/common/materialTagList',
    data: data,
    method: 'GET',
  });
};

export const getTagListV2 = (data: any) => {
  return request({
    url: '/waic/core/common/materialTagListV2',
    data: data,
    method: 'GET',
  });
};

export const getMaterialListByPage = (data:any) => {
  return request({
    url: '/waic/core/common/materialListByPage',
    method: 'POST',
    data,
  })
}

export const getMaterialList = (data: any) => {
  return request({
    url: '/waic/core/common/materialList',
    data: data,
    method: 'GET',
  });
};

export const getActivityData = (activityId: number) => {
  return request({
    url: '/waic/account/activity/join',
    method: 'POST',
    data: {
      data: {
        activityId
      }
    }
  })
}


export const getActivityList = () => {
  return request({
    url: '/waic/account/activity/list',
    method: 'POST'
  })
}

// 获取通用校验，例如首次调用优惠券、首次用户引导等
export const getCommonCheck = (data:any) => {
  return request({
    url: '/waic/account/periodic/checkin',
    method: 'POST',
    data,
  })
}