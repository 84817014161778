import { IMAGE_SCALE } from '@/components/EasyFabric/const'
import React, { ChangeEvent } from 'react'
import compressImage from './compressImage'
import { SceneType } from '@/constants'

export const queryString = (arg: object) => {
  return Object.entries(arg).reduce((result, [key, value], index) => {
    return `${result}${index ? '&' : ''}${key}=${value}`
  }, '?')
}

export const removeQuery = (keys: string[] = []) => {
  const params = new URLSearchParams(location.search)
  keys?.forEach(key => {
    params.delete(key)
  })
  return params
}

export const getQuery = () => {
  const ret: Record<string, string> = {}
  const params = new URLSearchParams(location.search)
  params.forEach((val, key) => {
    ret[key] = val
  })
  return ret
}
// 是否为十六进制颜色
export const isHexColor = (str: string) => {
  return /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(str)
}

// 生成随机UUID
export const generateUUID = () => {
  return URL.createObjectURL(new Blob()).slice(-36)
}

// file转base64
export const file2Base64 = (file: File): Promise<string> => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file)
    reader.onerror = err => {
      reject(err)
    }
    reader.onload = e => {
      resolve(e.target!.result as string)
    }
  })
}

// 是否为中文
export const isChinese = (str: string) => {
  return /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/.test(
    str,
  )
}

//
export const hexColor2RGB = () => {}

//
export const RGB2HexColor = () => {}

// 防抖
export const debounce = (fn: Function, timeout: number = 300) => {
  let timer: number
  return (...arg: any[]) => {
    clearInterval(timer)
    timer = window.setTimeout(() => {
      fn(...arg)
    }, timeout)
  }
}

// 节流
export const throttle = (fn: Function, timeout: number = 300) => {
  let timer: number | undefined
  return (...arg: any[]) => {
    if (!timer) {
      fn(...arg)
      timer = window.setTimeout(() => {
        timer = void 0
      }, timeout)
    }
  }
}

export const sleep = (timeout: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(void 0)
    }, timeout)
  })
}

export const toFixed = (num: string | number, place = 0) => {
  if (isNaN(Number(num))) {
    throw new Error('您传入的参数不是number类型')
  }
  const divisor = 10 ** place
  return Math.round(Number(num) * divisor) / divisor
}

export const enum2Opt = (arg: AnyObject) => {
  return Object.keys(arg).reduce((ret, key) => {
    if (isNaN(+key)) {
      return ret.concat({ label: key, value: arg[key] })
    }
    return ret
  }, [] as { label: string; value: string | number }[])
}

export const openImageDialog = (arg?: { multiple?: boolean; sceneType?: SceneType;notNeedCompress?:boolean }): Promise<File[]> => {
  return new Promise((resolve, reject) => {
    const accept = 'image/jpg, image/jpeg, image/png'
    const el = document.createElement('input')
    el.multiple = arg?.multiple || false
    el.accept = accept
    el.type = 'file'
    el.style.display = 'none'
    document.body.appendChild(el)

    console.log('====================================');
    console.log(111);
    console.log('====================================');
    el.onchange = async (e: AnyObject) => {
      const fileList: File[] = Array.from(e.target.files)
      const isAllImage = fileList.every(item => accept.includes(item.type.toLowerCase()))
      console.log(222);

      if (isAllImage) {
        if(arg?.notNeedCompress){
          resolve(fileList)
          return
        }
        const imageFileList = await compressImage(fileList, {
          maxWidthOrHeight: arg?.sceneType == SceneType.智能高清 ? 1600 : 2048,
        })

        resolve(imageFileList)
      } else {
      console.log(333);
      reject()
      }
      document.body.removeChild(el)
    }
    el.click()
  })
}

export const openFileDialog = (arg?: { multiple?: boolean }): Promise<File[]> => {
  return new Promise((resolve, reject) => {
    const accept = 'application/zip'
    const el = document.createElement('input')
    el.multiple = arg?.multiple || false
    el.accept = accept
    el.type = 'file'
    el.onchange = (e: AnyObject) => {
      const fileList: File[] = Array.from(e.target.files)
      console.log('fileList',fileList)
      // const isAllImage = fileList.every(item => accept.includes(item.type.toLowerCase()))
     resolve(fileList) 
    }
    el.click()
  })
}

export const copyText = (str: string) => {
  const el = document.createElement('input')
  el.setAttribute('readonly', 'readonly')
  el.style.position = 'absolute'
  el.style.left = el.style.top = '-9999px'
  el.value = str
  document.body.append(el)
  el.select()
  el.setSelectionRange(0, 99999)
  document.execCommand('copy')
  el.remove()
}

export const getImageSize = (src: string): Promise<{ width: number; height: number }> => {
  return new Promise(resolve => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.src = src
  })
}

export const getRandom = (start?: number, end?: number): number => {
  if (start === void 0) {
    return Math.random()
  }
  if (start !== void 0 && end === void 0) {
    return Math.floor(Math.random() * start)
  }

  return start + Math.floor((end! - start) * Math.random())
}

export const croppingAreaOfTheImage = (src: string): Promise<string> => {
  return new Promise(resolve => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = src
    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')!
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data

      // 查找最小宽高
      let minWidth = canvas.width
      let minHeight = canvas.height
      let maxWidth = 0
      let maxHeight = 0
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const alpha = data[(canvas.width * y + x) * 4 + 3]
          if (alpha > 0) {
            if (x < minWidth) minWidth = x
            if (x > maxWidth) maxWidth = x
            if (y < minHeight) minHeight = y
            if (y > maxHeight) maxHeight = y
          }
        }
      }

      const newWidth = maxWidth - minWidth + 1
      const newHeight = maxHeight - minHeight + 1

      const newCanvas = document.createElement('canvas')
      const newCtx = newCanvas.getContext('2d')!

      newCanvas.width = newWidth
      newCanvas.height = newHeight

      newCtx.drawImage(canvas, minWidth, minHeight, newWidth, newHeight, 0, 0, newWidth, newHeight)
      resolve(newCanvas.toDataURL())
    }
  })
}

// 计算图片压缩比例
export const fitScale = <T extends { width: number; height: number }>(canvasSize: T, imageSize: T, ratio?: number): number => {
  try {
    const ratioFlag = ratio || IMAGE_SCALE
    return Math.min(canvasSize.width / imageSize.width, canvasSize.height / imageSize.height) * ratioFlag
  } catch (error) {
    console.log('🚀 ~ fitScale ~ error:', error)
    return 0
  }
}

// 计算替换图片的压缩比例
export const fitScaleWithSize = <T extends { width: number; height: number }>(canvasSize: T, imageSize: T, oldImageSize: T): number => {
  try {
    const _imgScale = Math.min(oldImageSize.width / imageSize.width, oldImageSize.height / imageSize.height)
    const _canvasScale = fitScale(canvasSize, imageSize)
    if (_canvasScale >= _imgScale) {
      return _imgScale
    }
    return _canvasScale
  } catch (error) {
    console.log('🚀 ~ fitScaleWithSize ~ error:', error)
    return 0
  }
}

export const delay = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
