import { history } from 'umi';
import {deleteCookie} from "@/utils/cookie";
import {setIsLogin} from '@/states/store'
import {wsStore} from '@/states/ws'


export const logOut=()=>{
    deleteCookie('userId')
    deleteCookie('token')
    // state.close();
    setIsLogin(false)
    history.push({
      pathname:'/'
    })
    wsStore.onPortLogout()
    location.reload()
}

export const logoutReload=()=>{
  setIsLogin(false)
  history.push({
    pathname:'/'
  })
  location.reload()
}